// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-knowledge-base-tsx": () => import("../src/templates/knowledge-base.tsx" /* webpackChunkName: "component---src-templates-knowledge-base-tsx" */),
  "component---src-templates-release-notes-tsx": () => import("../src/templates/release-notes.tsx" /* webpackChunkName: "component---src-templates-release-notes-tsx" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dashboard-latest-news-tsx": () => import("../src/pages/dashboard/latest-news.tsx" /* webpackChunkName: "component---src-pages-dashboard-latest-news-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-notification-tsx": () => import("../src/pages/notification.tsx" /* webpackChunkName: "component---src-pages-notification-tsx" */),
  "component---src-pages-roadmap-tsx": () => import("../src/pages/roadmap.tsx" /* webpackChunkName: "component---src-pages-roadmap-tsx" */),
  "component---src-pages-status-tsx": () => import("../src/pages/status.tsx" /* webpackChunkName: "component---src-pages-status-tsx" */)
}

